<div id="login">
  
    <div class="page">

     

        <div class="containerP" *ngIf="cambioPass === false">
         
            <div class="left">
                <div class="login text-center">Bienvenido</div>
                <div class="eula">
                    <img src="assets/images/undraw_authentication_fsn5.svg" alt="" class="img-fluid" >
                </div>
            </div>
            <div class="right">
                <div class="row form" style="font-size: 12px;">
                    <form class="matPersonalizado" [formGroup]="formLogin" (ngSubmit)="login()"
                        style="width: 100% !important;">

                        <mat-form-field appearance="outline" class="col-md-12" tourAnchor="correo">
                            <mat-label>Número Colegiación</mat-label>
                            <input autocomplete="off" matInput minlength="5" required formControlName="usuario" style="color: white !important;">
                        </mat-form-field>


                        <mat-form-field appearance="outline" style="margin-top: 5%;" class="col-md-12">
                            <mat-label>Contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña"
                                formControlName="clave" required autocomplete="off">
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>

                        <div class="text-right cambioPass">¿Has olvidado tu <a (click)="recuperarUsuario()" style="color: aqua;">Usuario</a> o <a (click)="cambioPass = true" style="color: aqua;">Contraseña</a>
                        </div>

                        <!-- <mat-form-field appearance="outline" class="col-md-12" tourAnchor="password" ddf>
                            <mat-label>Password</mat-label>
                            <input autocomplete="off" matInput formControlName="clave" required>
                        </mat-form-field> -->


                        <div class="text-center mt-4 col-12">
                            <button style="width: 50%; background-color: #452272; color: white;" type="submit"
                                class="text-center" mat-raised-button>Entrar</button>
                        </div>
                    </form>


                </div>

            </div>
        </div>


        <div class="contenedorPadre" *ngIf="cambioPass === true">

            <mat-card class="container">
                <mat-card-content>

                    <div class="forms-container" *ngIf="vistaCambioPass === 'numeroColegiacion'">
                        <div class="img-signup">
                            <img src="assets/images/undraw_authentication_fsn5.svg" class="img-fluid">
                        </div>
                        <div class="signin-signup">

                            <div class="sign-in-form">
                                <h2 class="title">Recupera tu contraseña</h2>

                                <div class="row">
                                    <mat-form-field appearance="legacy" class="col-md-12">
                                        <mat-label>Número Colegiación</mat-label>
                                        <input matInput type="text" placeholder="Número Colegiación" [formControl]="numeroColegiacion" required autocomplete="off" required>
                                    </mat-form-field>
                                </div>

                                <app-loading [data]="4" *ngIf="(authService.responseCargando$ | async)"></app-loading>


                                <div style="display: flex; justify-content: space-around; margin-top: 8%;" *ngIf="!(authService.responseCargando$ | async)">
                                    <div class="text-right">
                                        <button mat-raised-button class="buttonSecundary" (click)="regresarCambioPass()">
                                            Volver
                                        </button>
                                    </div>
                                    <div class="text-left">
                                        <button mat-raised-button class="buttonPrincipal" (click)="confirmarDatosColegiacion()">Siguiente</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="forms-container" *ngIf="vistaCambioPass === 'confirmarDatos'">
                        <div class="img-signup">
                            <img src="assets/images/undraw_authentication_fsn5.svg" class="img-fluid">
                        </div>
                        <div class="signin-signup">

                            <div class="sign-in-form">
                                <h2 class="title">Recupera tu contraseña</h2>

                                <p>Debes completa por lo menos dos de los campos solicitados. Ejemplo: DNI y correo electrónico</p>

                                <form [formGroup]="formRecuperarPass">
                                    <div class="row">
                                        <mat-form-field appearance="legacy" class="col-md-12">
                                            <mat-label>DNI</mat-label>
                                            <input matInput type="text" placeholder="Número DNI" formControlName="noIdentificacion" required autocomplete="off">
                                        </mat-form-field>

                                        <mat-form-field appearance="legacy" class="col-md-12">
                                            <mat-label>Celular Registrado {{datosUsuario?.celular}}</mat-label>
                                            <input matInput type="text" placeholder="Numero Colegiación {{datosUsuario?.celular}}" formControlName="celular"  autocomplete="off" >
                                        </mat-form-field>

                                        <mat-form-field appearance="legacy" class="col-md-12">
                                            <mat-label>Teléfono Registrado {{datosUsuario?.telefono}}</mat-label>
                                            <input matInput type="text" placeholder="Numero Colegiación {{datosUsuario?.telefono}}" formControlName="telefono"  autocomplete="off" >
                                        </mat-form-field>

                                        <mat-form-field appearance="legacy" class="col-md-12">
                                            <mat-label>Correo Electrónico {{datosUsuario?.correoElectronico}}</mat-label>
                                            <input matInput type="text" placeholder="Numero Colegiación {{datosUsuario?.correoElectronico}}" formControlName="correoElectronico"  autocomplete="off" >
                                        </mat-form-field>
                               
                                    </div>
                                </form>

                                <app-loading [data]="4" *ngIf="(authService.responseCargando$ | async)"></app-loading>

                                <div style="display: flex; justify-content: space-around; margin-top: 8%;" *ngIf="!(authService.responseCargando$ | async)">
                                    <div class="text-right">
                                        <button mat-raised-button class="buttonSecundary" (click)="regresarCambioPass()">
                                            Cancelar
                                        </button>
                                    </div>
                                    <div class="text-left">
                                        <button mat-raised-button class="buttonPrincipal" (click)="validarFormularioCambioPass()">Siguiente</button>
                                    </div>
                                </div>

                                <div class="alert alert-secondary mt-4" role="alert" *ngIf="datosUsuario?.informacion != ''">
                                    {{datosUsuario?.informacion}}
                                 </div>

                            </div>

                        </div>
                    </div>

                </mat-card-content>
            </mat-card>
        </div>
    </div>

</div>